import React from "react";

const InstagramSvg = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0003 11.6665C13.6071 11.6665 11.667 13.6066 11.667 15.9998C11.667 18.3931 13.6071 20.3332 16.0003 20.3332C18.3936 20.3332 20.3337 18.3931 20.3337 15.9998C20.3337 13.6066 18.3936 11.6665 16.0003 11.6665ZM9.66699 15.9998C9.66699 12.502 12.5025 9.6665 16.0003 9.6665C19.4981 9.6665 22.3337 12.502 22.3337 15.9998C22.3337 19.4976 19.4981 22.3332 16.0003 22.3332C12.5025 22.3332 9.66699 19.4976 9.66699 15.9998Z"
        fill="#FAFAFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6667 5C7.53705 5 5 7.53705 5 10.6667V21.3333C5 24.4629 7.53705 27 10.6667 27H21.3333C24.4629 27 27 24.4629 27 21.3333V10.6667C27 7.53705 24.4629 5 21.3333 5H10.6667ZM3 10.6667C3 6.43248 6.43248 3 10.6667 3H21.3333C25.5675 3 29 6.43248 29 10.6667V21.3333C29 25.5675 25.5675 29 21.3333 29H10.6667C6.43248 29 3 25.5675 3 21.3333V10.6667Z"
        fill="#FAFAFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.0153 7.92175C24.4258 8.29121 24.4591 8.9235 24.0896 9.33401L24.0763 9.34883C23.7069 9.75934 23.0746 9.79261 22.6641 9.42316C22.2535 9.0537 22.2203 8.42141 22.5897 8.0109L22.6031 7.99608C22.9725 7.58557 23.6048 7.55229 24.0153 7.92175Z"
        fill="#FAFAFA"
      />
    </svg>
  );
};

export default InstagramSvg;
