import { createStyles } from "@mantine/styles";

export const useFooterStyles = createStyles(() => ({
  borderedLeft: {
    borderLeft: "1px solid #27282A",
    [`@media (max-width: 767px)`]: {
      borderLeft: "none",
    },
  },
  footerList: {
    fontSize: "18px",
    color: "#FAFAFA",
    [`@media (max-width: 767px)`]: {
      fontSize: "16px",
    },
    [`@media (max-width: 575px)`]: {
      fontSize: "12px",
    },
  },
  ulStyle: {
    padding: 0,
    margin: 0,
    listStyle: "none",
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    [`@media (max-width: 575px)`]: {
      gap: "8px",
    },
  },
  socialIconStyle: {
    width: "32px",
    height: "32px",
    [`@media (max-width: 992px)`]: {
      width: "21px",
      height: "21px",
    },
  },
}));
