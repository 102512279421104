import React from "react";

const FacebookSvg = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2455 3.91202C14.6833 2.47424 16.6333 1.6665 18.6667 1.6665H22.6667C23.219 1.6665 23.6667 2.11422 23.6667 2.6665V7.99984C23.6667 8.55212 23.219 8.99984 22.6667 8.99984H18.6667C18.5783 8.99984 18.4935 9.03496 18.431 9.09747C18.3685 9.15998 18.3333 9.24477 18.3333 9.33317V12.3332H22.6667C22.9746 12.3332 23.2654 12.475 23.4549 12.7178C23.6444 12.9605 23.7115 13.277 23.6368 13.5757L22.3035 18.909C22.1922 19.3542 21.7922 19.6665 21.3333 19.6665H18.3333V29.3332C18.3333 29.8855 17.8856 30.3332 17.3333 30.3332H12C11.4477 30.3332 11 29.8855 11 29.3332V19.6665H8C7.44772 19.6665 7 19.2188 7 18.6665V13.3332C7 12.7809 7.44772 12.3332 8 12.3332H11V9.33317C11 7.29984 11.8077 5.3498 13.2455 3.91202ZM18.6667 3.6665C17.1638 3.6665 15.7224 4.26353 14.6597 5.32623C13.597 6.38894 13 7.83028 13 9.33317V13.3332C13 13.8855 12.5523 14.3332 12 14.3332H9V17.6665H12C12.5523 17.6665 13 18.1142 13 18.6665V28.3332H16.3333V18.6665C16.3333 18.1142 16.781 17.6665 17.3333 17.6665H20.5526L21.3859 14.3332H17.3333C16.781 14.3332 16.3333 13.8855 16.3333 13.3332V9.33317C16.3333 8.71433 16.5792 8.12084 17.0168 7.68326C17.4543 7.24567 18.0478 6.99984 18.6667 6.99984H21.6667V3.6665H18.6667Z"
        fill="#FAFAFA"
      />
    </svg>
  );
};

export default FacebookSvg;
