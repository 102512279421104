import React from "react";

const FooterLogo = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 227 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M33.4523 0H0.678711V57.2689H33.4523V0Z" fill="#204BE5" />
      <path d="M102.596 0H69.8223V57.2689H102.596V0Z" fill="#204BE5" />
      <path d="M69.822 57.269H33.4521V80.0002H69.822V57.269Z" fill="#204BE5" />
      <path
        d="M149.633 22.0417L139.726 48.1656L137.08 55.0867H126.291L123.645 48.1656L113.738 22.0417H121.134L131.041 48.1656H132.33L142.305 22.0417H149.633Z"
        fill="white"
      />
      <path
        d="M162.554 9.69226H155.633V16.6134H162.554V9.69226Z"
        fill="white"
      />
      <path
        d="M162.554 22.0417H155.633V55.0867H162.554V22.0417Z"
        fill="white"
      />
      <path
        d="M199.02 22.0417V55.0867H192.167V28.9629H175.407V55.0867H168.554V22.0417H199.02Z"
        fill="white"
      />
      <path
        d="M226.462 22.0417V28.9628H216.284V55.0867H209.363V28.9628H205.021V22.0417H209.363V9.69226H216.284V22.0417H226.462Z"
        fill="white"
      />
      <path
        d="M123.621 63.6582C123.621 63.251 123.689 62.9118 123.893 62.5725C124.096 62.2332 124.367 61.9618 124.707 61.8261C125.046 61.6225 125.385 61.5547 125.792 61.5547H130.339V62.8439H125.792C125.521 62.8439 125.317 62.9118 125.182 63.1153C124.978 63.251 124.91 63.5225 124.91 63.726V64.3367C124.91 64.5403 124.978 64.7438 125.182 64.8795C125.385 65.0152 125.589 65.0831 125.792 65.0831H128.574C128.982 65.0831 129.321 65.151 129.66 65.3545C129.999 65.5581 130.271 65.8295 130.474 66.1009C130.678 66.3723 130.746 66.7795 130.746 67.1866V68.1365C130.746 68.5437 130.678 68.8829 130.474 69.2222C130.271 69.5615 129.999 69.8329 129.66 69.9686C129.321 70.1722 128.982 70.24 128.574 70.24H123.621V68.9508H128.642C128.914 68.9508 129.117 68.8829 129.253 68.6794C129.457 68.5437 129.524 68.3401 129.524 68.0687V67.1187C129.524 66.9152 129.457 66.7116 129.253 66.508C129.049 66.3723 128.846 66.2366 128.642 66.2366H125.86C125.453 66.2366 125.114 66.1688 124.775 65.9652C124.435 65.7616 124.164 65.4902 123.96 65.2188C123.757 64.9474 123.689 64.5403 123.689 64.1331V63.6582H123.621Z"
        fill="white"
      />
      <path
        d="M136.746 63.9635C136.746 63.4885 136.882 63.0814 137.085 62.7421C137.357 62.335 137.628 62.0636 138.035 61.86C138.442 61.6565 138.85 61.5208 139.325 61.5208H141.36C141.835 61.5208 142.31 61.6565 142.717 61.86C143.124 62.0636 143.464 62.4029 143.667 62.7421C143.871 63.1493 144.006 63.5564 144.006 63.9635V67.8312C144.006 68.3062 143.871 68.7133 143.667 69.0526C143.464 69.3918 143.124 69.7311 142.717 69.9347C142.31 70.1382 141.903 70.2739 141.36 70.2739H139.325C138.85 70.2739 138.375 70.1382 138.035 69.9347C137.628 69.7311 137.289 69.3918 137.085 69.0526C136.814 68.6454 136.746 68.2383 136.746 67.8312V63.9635ZM138.035 67.8312C138.035 68.1705 138.171 68.4419 138.442 68.6454C138.714 68.849 138.985 68.9847 139.392 68.9847H141.428C141.767 68.9847 142.107 68.849 142.378 68.6454C142.649 68.4419 142.785 68.1705 142.785 67.8312V63.9635C142.785 63.6242 142.649 63.3528 142.378 63.1492C142.107 62.9457 141.835 62.81 141.428 62.81H139.392C139.053 62.81 138.714 62.9457 138.442 63.1492C138.171 63.3528 138.035 63.6242 138.035 63.9635V67.8312Z"
        fill="white"
      />
      <path
        d="M150.006 61.4868H151.295V67.8651C151.295 68.2044 151.431 68.4758 151.634 68.6794C151.838 68.8829 152.109 69.0186 152.449 69.0186H156.791V70.3079H152.449C151.974 70.3079 151.567 70.1722 151.227 69.9686C150.82 69.765 150.549 69.4258 150.345 69.0865C150.142 68.6794 150.006 68.2722 150.006 67.8651V61.4868Z"
        fill="white"
      />
      <path
        d="M162.792 61.4868H164.081V67.8651C164.081 68.2044 164.217 68.4758 164.421 68.6794C164.624 68.8829 164.963 69.0186 165.303 69.0186H167.474C167.813 69.0186 168.085 68.8829 168.356 68.6794C168.627 68.4758 168.695 68.2044 168.695 67.8651V61.4868H169.985V67.8651C169.985 68.3401 169.849 68.7472 169.645 69.0865C169.442 69.4936 169.102 69.765 168.763 69.9686C168.356 70.1722 167.949 70.3079 167.474 70.3079H165.303C164.828 70.3079 164.421 70.1722 164.013 69.9686C163.606 69.765 163.335 69.4258 163.131 69.0865C162.928 68.6794 162.792 68.2722 162.792 67.8651V61.4868Z"
        fill="white"
      />
      <path
        d="M175.984 61.4868H183.177V62.776H180.259V70.3079H178.97V62.776H176.052V61.4868H175.984Z"
        fill="white"
      />
      <path
        d="M189.177 61.4868H190.466V70.3079H189.177V61.4868Z"
        fill="white"
      />
      <path
        d="M196.466 63.9635C196.466 63.4885 196.602 63.0814 196.805 62.7421C197.077 62.335 197.348 62.0636 197.755 61.86C198.162 61.6565 198.569 61.5208 199.044 61.5208H201.08C201.555 61.5208 202.03 61.6565 202.437 61.86C202.844 62.0636 203.183 62.4029 203.387 62.7421C203.591 63.1493 203.726 63.5564 203.726 63.9635V67.8312C203.726 68.3062 203.591 68.7133 203.387 69.0526C203.183 69.3918 202.844 69.7311 202.437 69.9347C202.03 70.1382 201.623 70.2739 201.08 70.2739H199.044C198.569 70.2739 198.094 70.1382 197.755 69.9347C197.348 69.7311 197.009 69.3918 196.805 69.0526C196.534 68.6454 196.466 68.2383 196.466 67.8312V63.9635ZM197.755 67.8312C197.755 68.1705 197.891 68.4419 198.162 68.6454C198.434 68.849 198.705 68.9847 199.112 68.9847H201.148C201.487 68.9847 201.826 68.849 202.098 68.6454C202.369 68.4419 202.505 68.1705 202.505 67.8312V63.9635C202.505 63.6242 202.369 63.3528 202.098 63.1492C201.826 62.9457 201.555 62.81 201.148 62.81H199.112C198.773 62.81 198.434 62.9457 198.162 63.1492C197.891 63.3528 197.755 63.6242 197.755 63.9635V67.8312Z"
        fill="white"
      />
      <path
        d="M209.727 61.4868H211.423L215.291 68.4079V61.4868H216.58V70.3079H214.883L211.016 63.4546V70.3079H209.727V61.4868Z"
        fill="white"
      />
    </svg>
  );
};

export default FooterLogo;
