import React from "react";

const LinkedInSvg = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3596_875)">
        <path
          d="M26.0003 1.97314H4.66699C2.45785 1.97314 0.666992 3.76401 0.666992 5.97314V26.0265C0.666992 28.2356 2.45786 30.0265 4.66699 30.0265H26.0003C28.2095 30.0265 30.0003 28.2356 30.0003 26.0265V5.97314C30.0003 3.76401 28.2095 1.97314 26.0003 1.97314Z"
          stroke="white"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M13.4268 24.9333V16.64C13.425 16.0541 13.5389 15.4735 13.7619 14.9317C13.9849 14.3899 14.3127 13.8973 14.7264 13.4824C15.1401 13.0675 15.6316 12.7382 16.1728 12.5136C16.714 12.289 17.2942 12.1733 17.8801 12.1733C19.0647 12.1733 20.2009 12.6439 21.0385 13.4816C21.8762 14.3193 22.3468 15.4554 22.3468 16.64V24.9333"
          stroke="white"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M8.32031 10.8936V24.9336"
          stroke="white"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M8.32004 9.62689C9.02696 9.62689 9.60004 9.05382 9.60004 8.34689C9.60004 7.63997 9.02696 7.06689 8.32004 7.06689C7.61311 7.06689 7.04004 7.63997 7.04004 8.34689C7.04004 9.05382 7.61311 9.62689 8.32004 9.62689Z"
          fill="white"
        />
        <path
          d="M13.4268 10.8936V17.2802"
          stroke="white"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_3596_875">
          <rect width="32" height="32" rx="2" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LinkedInSvg;
